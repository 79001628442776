
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "services-request-transport",
    emit: ["refreshData"],
    props: {
        transport: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
        Datepicker,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let isUpdate = false as boolean;
        let structures = ref([]);
        let analyticAccounts = ref([]);

        let formData = new FormData();
        let errors: any = ref();



        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editTransportModalRef = ref<null | HTMLElement>(null);
        const requestTransportDefault = {
            id: null,
            motif: "",
            from: "",
            structure_type:1,
            structure_code: "",
            analytic_account_type:1,
            analytic_account_code: "",
            arrival_date: "",
            departure_date: "",
            description: "",
            path:"",
            state:-1,
            guest:[],
            first_name:"",
            last_name:"",
            id_card: 0,
            id_number: "",
            car_type: 0,
            selectedFile:File,

        };
        const requestTransportData = ref<any>({});
        Object.assign(requestTransportData.value, requestTransportDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            // code: Yup.string().required().label(translate("employeeCodeLabel")),
            // last_name: Yup.string().required().label(translate("employeeLastNameLabel")),
            // first_name: Yup.string().required().label(translate("employeeFirstNameLabel")),
            // gender: Yup.string().required().label(translate("employeeGenderLabel")),
            // civil_status: Yup.string().required().label(translate("employeeCivilStatusLabel")),
            // id_number: Yup.string().required().label(translate("employeeIdNumberLabel")),
            // passport_number: Yup.string().required().label(translate("employeePassportNumberLabel")),
            // email: Yup.string().email().required().label(translate("employeeEmailLabel")),
            // phone: Yup.string().required().label(translate("employeePhoneLabel")),
            // address: Yup.string().required().label(translate("employeeAddressLabel")),
            // structure_type: Yup.number().required().label(translate("employeeStructureTypeLabel")),
            // structure_code: Yup.string().required().label(translate("employeeStructureCodeLabel")),
            // position_id: Yup.number().required().label(translate("employeePositionIdLabel")),
            // nationality: Yup.string().required().label(translate("employeeNationalityLabel")),
            // analytic_account_code: Yup.string().nullable().label(translate("employeeAnalyticAccountCodeLabel")),
            // is_driver: Yup.boolean().label(translate("employeeIsDriverLabel")),
            // is_user: Yup.boolean().label(translate("employeeIsUserLabel")),
            // is_head: Yup.boolean().label(translate("employeeIsHeadLabel")),
        });

        const submit = (values, actions) => {
            errors.value = null;


            // if (!submitButtonRef.value) {
            //     return;
            // }

            // addGuest();


            // formData = new FormData();

            // Disable button
            // submitButtonRef.value.disabled = true;
            // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.transport.id){
                requestTransportData.value.id = props.transport.id;
                 formData.append('id', requestTransportData.value.id);

            }



            let config = { headers: { 'Content-Type': 'multipart/form-data' } }

            // requestTransportData.value.guest=JSON.stringify(requestTransportData.value.guest);


            formData.append('motif', requestTransportData.value.motif);
            formData.append('from', requestTransportData.value.from);
            formData.append('structure_code', requestTransportData.value.structure_code);
            formData.append('analytic_account_code', requestTransportData.value.analytic_account_code);

            // let b = requestTransportData.value.arrival_date.toString().indexOf(+'GMT');


            formData.append('arrival_date', dateFormat(requestTransportData.value.arrival_date));
            formData.append('departure_date', dateFormat(requestTransportData.value.departure_date));

            // }


            // const serialize = (item) => {
            //     requestTransportData.value.guests.push(item + "<#>,");
            //     };

            //      requestTransportData.value.guests.forEach(serialize);
            formData.append('description', requestTransportData.value.description);
            formData.append('guest', requestTransportData.value.guest);

            (requestTransportData.value.state == -1) ? formData.append('state', '0') :




//             // for (var i = 0; i < requestTransportData.value.guest.length; i++) {
//             // formData.append('guests[]', requestTransportData.value.guest);
//             requestTransportData.value.guest.forEach( guest => {
//     formData.append('guests[]', guest);
// })


disableSubmitButton(submitButtonRef);

            axios.post(ApiRoutes.transports.updateOrCreate,formData,config)
                .then((response: AxiosResponse) => {
                    enableSubmitButton(submitButtonRef);


                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }

                    if (response.data && response.data.success) {
                        requestTransportData.value.guest=[];


                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editTransportModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {
                        // requestTransportData.value.guest=[]

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    requestTransportData.value.guest=[]

                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {
                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                    }

                });
        };



        const getAnalyticAccounts = async () => {

            var response =  (requestTransportData.value.analytic_account_type == 1) ?  await axios.get(ApiRoutes.analyticAccounts.list)
                                                                                      :  await axios.get(ApiRoutes.analyticAccountsIntra.list);

            analyticAccounts.value = response.data;


        };

        const dateFormat = (value) => {
            return moment(value).format('YYYY-MM-DD');
        };


            // updating the status of the request
            // this function is used when using rejected and accpeted btns
            const updateState = async (btn) => {

                let status = (btn == 'accepted') ? requestTransportData.value.state = '1' : requestTransportData.value.state = '2';

                // requestMaintenanceData.value.state = status;
                formData.append('state',status);
                const savedBtn: HTMLElement = document.getElementById('kt_modal_edit_transport_submit') as HTMLElement
                savedBtn.click();

            };



        const getStructures = async () => {
            const level = (requestTransportData.value) ? requestTransportData.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        // file upload config

        const onFileChanged =  async (event) => {
             console.log(event.target.files[0]);

             if( (event.target.files[0].type != "application/pdf") &&
                 (event.target.files[0].type != "image/png" ) &&
                 (event.target.files[0].type != "image/jpg" ) &&
                 (event.target.files[0].type != "image/jpeg" ) &&
                  (event.target.files[0].size >1000000))

             {
                 Swal.fire({
                            text: "le fichier ne peux pas depasser 1 MB, et vous pouvez charger seulement fichier du type PDF ou IMAGE",
                            icon: "warning",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
             }else {
                    requestTransportDefault.selectedFile=event.target.files[0];
                    formData.append('file',event.target.files[0]);
                    const element: HTMLElement = document.getElementById('fileName') as HTMLElement
                    element.innerHTML = event.target.files[0].name;
             }

        };

        const addGuest = () => {

            if (requestTransportData.value.first_name != "" &&
                requestTransportData.value.last_name != "" &&
             requestTransportData.value.id_card != "" &&
             requestTransportData.value.id_number != "" &&
             requestTransportData.value.car_type != "")

             {

                // and this array is used to display data to the user
                var arr_display = new Array( requestTransportData.value.first_name,requestTransportData.value.last_name,requestTransportData.value.id_card,requestTransportData.value.id_number,requestTransportData.value.car_type);

                // requestTransportData.value.guests.push(arr_send);
                requestTransportData.value.guest.push(arr_display);
                requestTransportData.value.first_name = "";
                requestTransportData.value.last_name = "";
                requestTransportData.value.id_card = 0;
                requestTransportData.value.id_number = "";
                requestTransportData.value.car_type = 0;

            }
        };

        const removeGuest = (index) => {
            requestTransportData.value.guest.splice(index, 1);
            requestTransportData.value.guests.splice(index, 1);
        };

        // const sliceIntoChunks = (arr,5) => {
        //                 const res = [];
        //                 for (let i = 0; i < requestTransportData.value.guest.length; i += 5) {
        //                     const chunk = requestTransportData.value.guest.slice(i, i + 5);
        //                     requestTransportData.value.guest.push(chunk);
        //                 }

        // };

        onMounted(() => {
            // requestTransportData.value.structure_code=props.transport.structure_code
            getStructures();
            getAnalyticAccounts();


            const modal = document.getElementById('kt_modal_edit_transport');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(requestTransportData.value, requestTransportDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;


                if (props.transport) {
                    isUpdate = true;

                    requestTransportData.value.state = props.transport.state;


                    requestTransportData.value.analytic_account_type = props.transport.analytic_account_type;
                    getAnalyticAccounts();

                    // Fill Intra fields
                    Object.keys(props.transport).forEach((key) => {
                        if (requestTransportDefault.hasOwnProperty(key)) requestTransportData.value[key] = props.transport[key];
                    });
                }else{
                    requestTransportData.value.guest=[];
                }
            });



        });

        return {
            errors,
            resetField,
            checkField,

            translate,
            structures,
            analyticAccounts,
            getAnalyticAccounts,
            getStructures,
            updateState,
            requestTransportData,
            validationSchema,
            submit,
            submitButtonRef,
            editTransportModalRef,
            onFileChanged,
            dateFormat,
            addGuest,
            removeGuest,
        };
    },
});
