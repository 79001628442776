
const checkField = (errors, field: string): boolean => {
    return errors && errors[field];
};

const resetField = (errors, field: string): boolean => {
    if (!errors || typeof errors[field] == undefined) return false;
    return delete errors[field];
};

const enableSubmitButton = (submitButtonRef) => {

    if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;

        submitButtonRef.value?.removeAttribute("data-kt-indicator");
    }
};

const disableSubmitButton = (submitButtonRef) => {
    if (!submitButtonRef.value) {
        return;
    }

    // Disable button
    submitButtonRef.value.disabled = true;
    // Activate indicator
    submitButtonRef.value.setAttribute("data-kt-indicator", "on");
};

export {
    enableSubmitButton,
    disableSubmitButton,
    checkField,
    resetField
};
