
import { defineComponent, toRef } from "vue";

export default defineComponent({
    name: "field-error",
    props: {
        errors: null,
        field: null
    },
    setup(props) {

        let errors: any = toRef(props, 'errors');
        let field: any = toRef(props, 'field');

        return {
            errors,
            field
        };
    }
});
