
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";
import moment from 'moment';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineComponent({
    name: "search-transport-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let structures = ref([]);
        let positions = ref([]);
        let nationalities = ref([]);
        let analyticAccounts = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchEmployeeModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            motif: "",
            from: "",
            structure_type:1,
            structure_code: "",
            analytic_account_type:1,
            analytic_account_code: "",
            arrival_date: "",
            departure_date: "",
            description: "",
            path:"",
            guest:[],
            first_name:"",
            last_name:"",
            id_card: 0,
            id_number: "",
            car_type: 0,
        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }
            queryParams.value.arrival_date = dateFormat(queryParams.value.arrival_date);
            queryParams.value.departure_date = dateFormat(queryParams.value.departure_date);

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchEmployeeModalRef.value);
        };

        const getAnalyticAccounts = async () => {

            var response =  (queryParams.value.analytic_account_type == 1) ?  await axios.get(ApiRoutes.analyticAccounts.list)
                                                                                      :  await axios.get(ApiRoutes.analyticAccountsIntra.list);

            analyticAccounts.value = response.data;


        };

        const dateFormat = (value) => {
            return moment(value).format('YYYY-MM-DD');
        };



        const getStructures = async () => {
            const level = (queryParams.value) ? queryParams.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };






        onMounted(() => {
            getStructures();
            getAnalyticAccounts();

            const modal = document.getElementById('kt_modal_search_employee');



            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });
        });

        return {
            translate,
            structures,
            positions,
            nationalities,
            analyticAccounts,
            getAnalyticAccounts,
            getStructures,
            queryParams,
            submit,
            submitButtonRef,
            searchEmployeeModalRef,
            dateFormat,
        };
    },
});
