const ApiRoutes = {
    structures           : {
        index         : process.env.VUE_APP_API_URL + "/structures",
        updateOrCreate: process.env.VUE_APP_API_URL + "/structures",
        delete        : process.env.VUE_APP_API_URL + "/structures",
        names         : process.env.VUE_APP_API_URL + "/structures/names",
        list          : process.env.VUE_APP_API_URL + "/structures/list",
    },
    infrastructures      : {
        index         : process.env.VUE_APP_API_URL + "/infrastructures",
        updateOrCreate: process.env.VUE_APP_API_URL + "/infrastructures",
        delete        : process.env.VUE_APP_API_URL + "/infrastructures",
        names         : process.env.VUE_APP_API_URL + "/infrastructures/names",
        list          : process.env.VUE_APP_API_URL + "/infrastructures/list",
    },

    parks                : {
        index         : process.env.VUE_APP_API_URL + "/parks",
        updateOrCreate: process.env.VUE_APP_API_URL + "/parks",
        delete        : process.env.VUE_APP_API_URL + "/parks",
        names         : process.env.VUE_APP_API_URL + "/parks/names",
        list          : process.env.VUE_APP_API_URL + "/parks/list",
        types         : process.env.VUE_APP_API_URL + "/parks/types",
    },
    suppliers            : {
        index         : process.env.VUE_APP_API_URL + "/suppliers",
        updateOrCreate: process.env.VUE_APP_API_URL + "/suppliers",
        delete        : process.env.VUE_APP_API_URL + "/suppliers",
        list          : process.env.VUE_APP_API_URL + "/suppliers/list",
        types         : process.env.VUE_APP_API_URL + "/suppliers/types",
        statuses      : process.env.VUE_APP_API_URL + "/suppliers/statuses",
        activityFields: process.env.VUE_APP_API_URL + "/suppliers/activity/fields",
        activityCodes : process.env.VUE_APP_API_URL + "/suppliers/activity/codes",
    },
    suppliersIntra       : {
        index         : process.env.VUE_APP_API_URL + "/suppliers/intra",
        updateOrCreate: process.env.VUE_APP_API_URL + "/suppliers/intra",
        delete        : process.env.VUE_APP_API_URL + "/suppliers/intra",
        list          : process.env.VUE_APP_API_URL + "/suppliers/intra/list",
    },
    employees            : {
        index                 : process.env.VUE_APP_API_URL + "/employees",
        updateOrCreate        : process.env.VUE_APP_API_URL + "/employees",
        delete                : process.env.VUE_APP_API_URL + "/employees",
        positions             : process.env.VUE_APP_API_URL + "/employees/positions",
        list                  : process.env.VUE_APP_API_URL + "/employees/list",
        employees             : process.env.VUE_APP_API_URL + "/employees/employees",
        employeesPosition     : process.env.VUE_APP_API_URL + "/employees/employeesPosition",
        read                  : process.env.VUE_APP_API_URL + "/employees/read",
    },
    employeesIntra       : {
        index         : process.env.VUE_APP_API_URL + "/employees/intra",
        updateOrCreate: process.env.VUE_APP_API_URL + "/employees/intra",
        delete        : process.env.VUE_APP_API_URL + "/employees/intra",
        jobs          : process.env.VUE_APP_API_URL + "/employees/intra/jobs",
    },
    employeesSupplier    : {
        index         : process.env.VUE_APP_API_URL + "/employees/supplier",
        updateOrCreate: process.env.VUE_APP_API_URL + "/employees/supplier",
        delete        : process.env.VUE_APP_API_URL + "/employees/supplier",
        list          : process.env.VUE_APP_API_URL + "/employees/supplier/list",
    },
    warehouses           : {
        index         : process.env.VUE_APP_API_URL + "/warehouses",
        updateOrCreate: process.env.VUE_APP_API_URL + "/warehouses",
        delete        : process.env.VUE_APP_API_URL + "/warehouses",
        types         : process.env.VUE_APP_API_URL + "/warehouses/types",
        list          : process.env.VUE_APP_API_URL + "/warehouses/list",
    },
    ContractPriceRents   : {
        index         : process.env.VUE_APP_API_URL + "/contracts/rent",
        updateOrCreate: process.env.VUE_APP_API_URL + "/contracts/rent",
        delete        : process.env.VUE_APP_API_URL + "/contracts/rent",
        types         : process.env.VUE_APP_API_URL + "/contracts/rent/types",
        list          : process.env.VUE_APP_API_URL + "/contracts/rent/list",
    },
    productCategories    : {
        index         : process.env.VUE_APP_API_URL + "/products/categories",
        updateOrCreate: process.env.VUE_APP_API_URL + "/products/categories",
        delete        : process.env.VUE_APP_API_URL + "/products/categories",
        names         : process.env.VUE_APP_API_URL + "/products/categories/names",
        list          : process.env.VUE_APP_API_URL + "/products/categories/list",
    },
    productAttributes    : {
        index         : process.env.VUE_APP_API_URL + "/products/attributes",
        updateOrCreate: process.env.VUE_APP_API_URL + "/products/attributes",
        delete        : process.env.VUE_APP_API_URL + "/products/attributes",
        list          : process.env.VUE_APP_API_URL + "/products/attributes/list",
        count         : process.env.VUE_APP_API_URL + "/products/attributes/count",
        options       : process.env.VUE_APP_API_URL + "/products/attributes/options",
    },
    products             : {
        index         : process.env.VUE_APP_API_URL + "/products",
        updateOrCreate: process.env.VUE_APP_API_URL + "/products",
        delete        : process.env.VUE_APP_API_URL + "/products",
        brands        : process.env.VUE_APP_API_URL + "/products/brands",
        list          : process.env.VUE_APP_API_URL + "/products/list",
    },
    productInputs        : {
        index         : process.env.VUE_APP_API_URL + "/products/inputs",
        updateOrCreate: process.env.VUE_APP_API_URL + "/products/inputs",
        delete        : process.env.VUE_APP_API_URL + "/products/inputs",
    },
    productOutputs       : {
        index         : process.env.VUE_APP_API_URL + "/products/outputs",
        updateOrCreate: process.env.VUE_APP_API_URL + "/products/outputs",
        delete        : process.env.VUE_APP_API_URL + "/products/outputs",
        statuses      : process.env.VUE_APP_API_URL + "/products/outputs/statuses",
    },
    productAdjustments   : {
        index         : process.env.VUE_APP_API_URL + "/products/adjustments",
        updateOrCreate: process.env.VUE_APP_API_URL + "/products/adjustments",
        delete        : process.env.VUE_APP_API_URL + "/products/adjustments",
    },
    analyticAccounts     : {
        index         : process.env.VUE_APP_API_URL + "/analytic-accounts",
        updateOrCreate: process.env.VUE_APP_API_URL + "/analytic-accounts",
        delete        : process.env.VUE_APP_API_URL + "/analytic-accounts",
        list          : process.env.VUE_APP_API_URL + "/analytic-accounts/list",
    },
    analyticAccountsIntra: {
        index         : process.env.VUE_APP_API_URL + "/analytic-accounts/intra",
        updateOrCreate: process.env.VUE_APP_API_URL + "/analytic-accounts/intra",
        delete        : process.env.VUE_APP_API_URL + "/analytic-accounts/intra",
        list          : process.env.VUE_APP_API_URL + "/analytic-accounts/intra/list",
    },
    vehicles             : {
        index         : process.env.VUE_APP_API_URL + "/vehicles",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles",
        delete        : process.env.VUE_APP_API_URL + "/vehicles",
        types         : process.env.VUE_APP_API_URL + "/vehicles/types",
        brands        : process.env.VUE_APP_API_URL + "/vehicles/brands",
        models        : process.env.VUE_APP_API_URL + "/vehicles/models",
        colors        : process.env.VUE_APP_API_URL + "/vehicles/colors",
        fuels         : process.env.VUE_APP_API_URL + "/vehicles/fuels",
        gearboxes     : process.env.VUE_APP_API_URL + "/vehicles/gearboxes",
    },
    contracts             : {
        index         : process.env.VUE_APP_API_URL + "/contracts",
        updateOrCreate: process.env.VUE_APP_API_URL + "/contracts",
        delete        : process.env.VUE_APP_API_URL + "/contracts",
        types         : process.env.VUE_APP_API_URL + "/contracts/types",
    },
    vehicleInsurances    : {
        index         : process.env.VUE_APP_API_URL + "/vehicles/insurances",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles/insurances",
        delete        : process.env.VUE_APP_API_URL + "/vehicles/insurances",
        companies     : process.env.VUE_APP_API_URL + "/vehicles/insurances/companies",
    },
    vehicleDamages       : {
        index         : process.env.VUE_APP_API_URL + "/vehicles/damages",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles/damages",
        delete        : process.env.VUE_APP_API_URL + "/vehicles/damages",
        conditions    : process.env.VUE_APP_API_URL + "/vehicles/damages/conditions",
    },
    vehicleControls      : {
        index         : process.env.VUE_APP_API_URL + "/vehicles/controls",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles/controls",
        delete        : process.env.VUE_APP_API_URL + "/vehicles/controls",
        agencies      : process.env.VUE_APP_API_URL + "/vehicles/controls/agencies",
        checkpoints   : process.env.VUE_APP_API_URL + "/vehicles/controls/checkpoints",
    },
    vehicleAssignments   : {
        index         : process.env.VUE_APP_API_URL + "/vehicles/assignments",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles/assignments",
        delete        : process.env.VUE_APP_API_URL + "/vehicles/assignments",
    },
    vehicleMissions      : {
        index         : process.env.VUE_APP_API_URL + "/vehicles/missions",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles/missions",
        delete        : process.env.VUE_APP_API_URL + "/vehicles/missions",
        itineraries   : process.env.VUE_APP_API_URL + "/vehicles/missions/itineraries",
    },
    vehicleMaintenances  : {
        index         : process.env.VUE_APP_API_URL + "/vehicles/maintenances",
        updateOrCreate: process.env.VUE_APP_API_URL + "/vehicles/maintenances",
        delete        : process.env.VUE_APP_API_URL + "/vehicles/maintenances",
        types         : process.env.VUE_APP_API_URL + "/vehicles/maintenances/types",
        breakdowns    : process.env.VUE_APP_API_URL + "/vehicles/maintenances/breakdowns",
        validate      : process.env.VUE_APP_API_URL + "/vehicles/maintenances/validate",
    },
    maintenances         : {
        index         : process.env.VUE_APP_API_URL + "/requests/maintenances",
        updateOrCreate: process.env.VUE_APP_API_URL + "/requests/maintenances",
        delete        : process.env.VUE_APP_API_URL + "/requests/maintenances",
        types         : process.env.VUE_APP_API_URL + "/requests/maintenances/types",
        breakdowns    : process.env.VUE_APP_API_URL + "/requests/maintenances/breakdowns",
        order         : process.env.VUE_APP_API_URL + "/requests/maintenances/order",
    },
    transports           : {
        index         : process.env.VUE_APP_API_URL + "/requests/transports",
        updateOrCreate: process.env.VUE_APP_API_URL + "/requests/transports",
        delete        : process.env.VUE_APP_API_URL + "/requests/transports",
        types         : process.env.VUE_APP_API_URL + "/requests/transports/types",
        breakdowns    : process.env.VUE_APP_API_URL + "/requests/transports/breakdowns",
        order         : process.env.VUE_APP_API_URL + "/requests/transports/order",
    },
    roles                : {
        index         : process.env.VUE_APP_API_URL + "/roles",
        updateOrCreate: process.env.VUE_APP_API_URL + "/roles",
        read          : process.env.VUE_APP_API_URL + "/roles",
        delete        : process.env.VUE_APP_API_URL + "/roles",
        permissions   : process.env.VUE_APP_API_URL + "/roles/permissions",
    },
    dropdownOptions      : {
        index         : process.env.VUE_APP_API_URL + "/dropdowns-options",
        updateOrCreate: process.env.VUE_APP_API_URL + "/dropdowns-options",
    },
    countries            : {
        nationalities : process.env.VUE_APP_API_URL + "/countries/nationalities",
    },
};

export default ApiRoutes;
