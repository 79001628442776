import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-0 list-unstyled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.errors && _ctx.errors[_ctx.field])
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors[_ctx.field], (error) => {
          return (_openBlock(), _createElementBlock("li", null, _toDisplayString(error), 1))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}